@import 'styles/jurta-colors';

.panel-label {
  font-size: 1rem;
  color: $whiteEdgar;
  font-weight: 500;
  margin-bottom: 16px;
  .required {
    color: $orange;
  }
  @media screen and (max-width: 640px) {
    font-size: 0.875rem;
  }
}

.panel-textarea {
  border: 1px solid $ash-gray;
  border-radius: 12px;
  background: transparent;
  height: 110px;
  width: 100%;
  resize: none;
  padding: 12px;
  color: $whiteEdgar;
  font-size: 1rem;
}

.panel-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.panel-input {
  border: 1px solid $ash-gray;
  border-radius: 12px;
  background: transparent;
  padding: 12px;
  width: 100%;
  color: $whiteEdgar;
  outline: none;
  box-shadow: none;
  font-size: 16px;
  font-weight: 400;
  height: 44px;
  @media screen and (max-width: 640px) {
    font-size: 0.875rem;
  }
}

.panel-btn-link {
  text-align: center;
  font-size: 1rem;
  color: $purple;
  border: none;
  background: none;
  width: 100%;
}

.panel-action-btns {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  app-button {
    width: 100%;
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
}

.modal-body {
  padding: 32px;
  width: 592px;

  @media screen and (max-width: 1180px) {
    width: 100vw;
  }
}

.modal-body-info {
  display: flex;
  align-items: center;
  padding: 32px 69px;
  @media (max-width: 1180px) {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
  }
}

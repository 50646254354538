/* Jurta Color Palette */

$white: #FFFFFF;
$whiteEdgar: #EDEDED;
$veryBlack: #212025;
$gray: #333237;
$graySecondary: #BABABA;
$grayDisabled: #464550;
$light-gray: #4D4B53;
$ash-gray: #6A6877;
$silverGray: #A7A7A7;
$accent: #26BCBC;
$orange: #FA6009;
$red: #FF3333;
$purple-gradient: linear-gradient(to right, #572BA3, #8D48FF);
$yellow: #FAC600;
$purple: #8D4BFF;
